import { Chain } from 'viem';

export const redstone = {
  id: 690,
  network: 'redstone',
  name: 'Redstone',
  nativeCurrency: { name: 'Redstone Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.redstonechain.com/'],
    },
    public: {
      http: ['https://rpc.redstonechain.com/'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://explorer.redstone.xyz/',
    },
  },
  testnet: true,
} as const satisfies Chain;
